<template>
  <dash-page-new
      :title="entity.name || $t('ViewReferral')"
      :subtitle="$t('Details')"
      :root="$t('referral.system')"
      :root-location="businessDashLink('referral')"
      icon="mdi-account-star"
  >

    <template #header_action>
      <v-btn @click="openEditWindow" :color="wsATTENTION" dark class="noCaps">
        {{ $t('referral.edit') }}
      </v-btn>
    </template>

    <template #default>
      <ws-accordion
          :items="navigationItems"
          class="mt-5"
      >

        <!-- ACTION-->
        <template #action.users>
          <h4 class="mr-5">{{  users.length || null  }}</h4>
        </template>

        <template #action.payments>
          <v-btn v-if="entity.sum_to_pay > 0" @click="openAddPayment" class="noCaps mr-5" :color="wsACCENT" rounded dark>
            <v-icon>mdi-plus</v-icon>
            {{ $t('referral.payments.add') }}
          </v-btn>
        </template>

        <template #action.orders>
          <h4 class="mr-5">{{  orders.length || null  }}</h4>
        </template>

        <!-- ITEMS-->

        <template #item.statistics>
          <v-sheet
          >
            <v-simple-table >
              <tbody>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.profit') }}</h4></td>
                <td>
                  <h4>{{entity.referral_profit}}</h4>
                </td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Orders') }}</h4></td>
                <td>
                  <h4>{{orders.length}}</h4>
                </td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Users') }}</h4></td>
                <td><h4>{{users.length}}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.clicks') }}</h4></td>
                <td><h4>{{entity.clicks}}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.payments.sum_to_pay') }}</h4></td>
                <td>
                  <v-chip :color="wsACCENT" dark >
                    <h3>{{entity.sum_to_pay || 0}} {{ BUSINESS_CURRENCY }}</h3>
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.payments.payed') }}</h4></td>
                <td><h4>{{entity.total_payed || 0}} {{ BUSINESS_CURRENCY }}</h4></td>
              </tr>

              </tbody>
            </v-simple-table>
          </v-sheet>
        </template>

        <template #item.info>
          <v-sheet
          >
            <v-simple-table >
              <tbody>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Name') }}</h4></td>
                <td><h4>{{entity.name}}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Code') }}</h4></td>
                <td><h4>{{entity.code}}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Active') }}</h4></td>
                <td><h4>{{entity.is_active ? $t('Yes') : $t('No')}}</h4></td>
              </tr>
              <!--            <tr>-->
              <!--              <td width="100px"><h4 :style="`color : ${wsACCENT}`">{{ $t('DateRegistered') }}</h4></td>-->
              <!--              <td><h4>{{entity.date}}</h4></td>-->
              <!--            </tr>-->
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.commission_type.name') }}</h4></td>
                <td><h4>{{ entity.commission_type === 'percent' ? $t('Percent') : $t('Sum') }}</h4></td>
              </tr>
              <tr v-if="entity.commission_type === 'percent'">
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('Percent') }}</h4></td>
                <td><h4>{{entity.percent || 0}} %</h4></td>
              </tr>
              <tr v-if="entity.commission_type === 'sum'">
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.commission.order_price') }}</h4></td>
                <td><h4>{{entity.order_price || 0}} {{ BUSINESS_CURRENCY }}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.user_price.name') }}</h4></td>
                <td><h4>{{entity.click_price ? `${entity.user_price} ${BUSINESS_CURRENCY}` : $t('None')}}</h4></td>
              </tr>
              <tr>
                <td width="150px"><h4 :style="`color : ${wsACCENT}`">{{ $t('referral.click_price.name') }}</h4></td>
                <td><h4>{{entity.click_price ? `${entity.click_price} ${BUSINESS_CURRENCY}` : $t('None')}}</h4></td>
              </tr>

              </tbody>
            </v-simple-table>
          </v-sheet>
        </template>

        <template #item.payments>
          <ws-data-table
              :items="payments"
              :headers="paymentsHeaders"
          >

            <template #item.date="{item}">
              <h5 class="text-no-wrap">
                <v-icon :color="wsACCENT">mdi-calendar</v-icon>
                {{ PARSE_TIME_STAMP(item.date , true) }}
              </h5>
            </template>

            <template #item.sum="{item}">
              <h5 class="text-center">{{ item.sum }}</h5>
            </template>

          </ws-data-table>
        </template>

        <template #item.users>
          <ws-data-table
              :items="users"
              :headers="usersHeaders"
          >
            <template #item.date="{item}">
              <h5 class="text-no-wrap">
                <v-icon :color="wsACCENT">mdi-calendar</v-icon>
                {{ PARSE_TIME_STAMP(item.date , true) }}
              </h5>
            </template>

            <template #item.name="{item}">
              <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
              <h6 class="font-weight-medium"> {{ item.email }}</h6>
            </template>

            <template #item.orders="{item}">
              <h5 class="text-center">{{ item.orders }}</h5>
            </template>

          </ws-data-table>
        </template>

        <template #item.orders>
          <ws-data-table
              :items="orders"
              :headers="ordersHeaders"
          >

            <template #item.date="{item}">
              <h5 class="text-no-wrap">
                <v-icon :color="wsACCENT">mdi-calendar</v-icon>
                {{ PARSE_TIME_STAMP(item.date , true) }}
              </h5>
            </template>

            <template #item.name="{item}">
              <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
            </template>

            <template #item.price="{item}">
              <h5 class="text-center">{{ item.price }}</h5>
            </template>

          </ws-data-table>
        </template>

      </ws-accordion>
    </template>



    <template #dialog>
      <ws-dialog
          v-model="displayPaymentDialog"
          :title="$t('referral.payments.make')"
          @save="addReferralPayment"
      >
        <ws-text-field
            v-model="entityData.sum"
            :label="$t('Sum')"
            :placeholder="$t('EnterSum')"
            number
        >

        </ws-text-field>

      </ws-dialog>
      <ws-dialog
          v-model="displayDialog"
          @save="saveReferral"
          :title="$t('referral.edit')"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />
        <ws-text-field
            v-model="entityData.code"
            :label="$t('Code')"
            :placeholder="$t('referral.code.enter')"
            :error="codeError"
            @input="codeError = false"
            clearable
            class="mt-5"
        />

        <v-row>
          <v-col>
            <ws-select
                v-model="entityData.commission_type"
                :items="commissionTypesSelect"
                :label="$t('referral.commission_type.name')"
                :placeholder="$t('referral.commission_type.choose')"
                class="mt-5"
            />
          </v-col>
          <v-col>
            <ws-text-field
                v-if="entityData.commission_type === 'percent'"
                v-model="entityData.percent"
                :label="$t('referral.percent.name')"
                :placeholder="$t('referral.percent.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
                prepend-inner-icon="mdi-percent-circle"
                key="percent"
            />
            <ws-text-field
                v-else-if="entityData.commission_type === 'sum'"
                v-model="entityData.order_price"
                :label="$t('Sum')"
                :placeholder="$t('referral.sum.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
                key="sum"
            />
          </v-col>
        </v-row>
        <h5 class="mt-3"  v-if="entityData.commission_type === 'percent'" >
          <v-icon :color="wsACCENT">mdi-information</v-icon>
          {{ $t('referral.percent.description') }}
        </h5>
        <h5 class="mt-3"  v-if="entityData.commission_type === 'sum'" >
          <v-icon :color="wsACCENT">mdi-information</v-icon>
          {{ $t('referral.sum.description') }}
        </h5>
        <v-row>
          <v-col>
            <ws-text-field
                v-model="entityData.user_price"
                :label="$t('referral.user_price.name')"
                :placeholder="$t('referral.user_price.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
            />
          </v-col>
          <v-col>
            <ws-text-field
                v-model="entityData.click_price"
                :label="$t('referral.click_price.name')"
                :placeholder="$t('referral.click_price.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
            />
          </v-col>
        </v-row>

        <ws-switch
            v-model="entityData.is_active"
            :placeholder="entityData.is_active ? $t('Active') : $t('NotActive')"
            class="mt-3"
        />
      </ws-dialog>

    </template>


  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "viewReferral",
  props: {
    code : {
      type : String
    }
  },
  data() {
    return {
      entity : {},
      users : [],
      orders : [],
      payments : [],
      displayPaymentDialog : false,
      entityData : {},
      displayDialog : false,
    }
  },
  computed : {

    commissionTypesSelect() {
      return [
        { text : this.$t('Percent') , value : 'percent' },
        { text : this.$t('Sum') , value : 'sum' }
      ]
    },

    paymentsHeaders() {
      return [
        { text : this.$t("Date")  , value : 'date' , width : 10 },
        { text : this.$t("Sum") , value : 'sum' },

      ]
    },
    ordersHeaders() {
      return [
        { text : this.$t("Date")  , value : 'date' , width : 10 },
        { text : this.$t("Name")  , value : 'name'              },
        { text : this.$t("Sum") , value : 'price', width : 10 },

      ]
    },
    usersHeaders() {
      return [
        { text : this.$t("DateRegistered") , value : 'date' , width : 10 },
        { text : this.$t("HumanName") , value : 'name' },
        { text : this.$t("Orders") , value : 'orders' , width: 10 },

      ]
    },
    navigationItems() {
      return [
        { name : this.$t('Statistics') ,
          subtitle : this.$t('referral.statistics'),
          value : "statistics" ,
          icon : "mdi-chart-areaspline",
          expanded : true
        },
        { name : this.$t('Information') ,
          subtitle : this.$t('referral.details'),
          value : "info" ,
          icon : "mdi-information",
          expanded : false
        },
        { name : this.$t('referral.payments.name') ,
          subtitle : this.$t('referral.payments.description'),
          value : "payments" ,
          icon : "mdi-cash",
          expanded : false
        },
        { name : this.$t('Users') ,
          subtitle : this.$t('referral.users'),
          value : "users" ,
          icon : "mdi-account-multiple",
          expanded : false
        },
        { name : this.$t('Orders') ,
          subtitle : this.$t('referral.orders'),
          value : "orders" ,
          icon : "mdi-receipt-text-check-outline",
          expanded : false
        }
      ]
    }
  },
  methods : {
    ...mapActions('referral',[
        "GET_REFERRAL_DETAILS",
        "ADD_REFERRAL_PAYMENT",
        "ADD_EDIT_REFERRAL"
    ]),

    validateCode() {
      if ( this.entityData.code && !this.entityData.code.match("^[a-zA-Z0-9_-]*$")) {
        this.codeError = this.$t('referral.code.regex_error')
        this.notify(this.$t('referral.code.regex_error') , 'warning')
        return false
      }
      return true
    },

    async saveReferral() {

      if ( this.entityData.percent > 100 ) {
        this.entityData.percent = 100
      }

      if ( this.entityData.percent < 0 ) {
        this.entityData.percent = 0
      }

      if ( this.entityData.order_price < 0 ) {
        this.entityData.order_price = null
      }

      if ( this.entityData.user_price < 0 ) {
        this.entityData.user_price = null
      }

      if ( this.entityData.click_price < 0 ) {
        this.entityData.click_price = null
      }

      if ( !this.validateCode() ) {
        return
      }

      let result = await this.ADD_EDIT_REFERRAL(this.entityData)
      if ( !result )  {
        return
      }


      this.initPage()
      this.notify(this.$t('ChangesSaved'))
      this.displayDialog = false


    },

    openEditWindow() {
      this.addNew = false
      this.entityData = Object.assign({} , this.entity )
      this.displayDialog = true
    },

    async addReferralPayment() {
      this.entityData.uuid = this.entity.uuid
      let result = await this.ADD_REFERRAL_PAYMENT(this.entityData)
      if ( !result ) {
        return
      }
      this.payments.unshift(result)
      this.entity.sum_to_pay -= this.entityData.sum
      this.displayPaymentDialog = false
    },
    openAddPayment() {
      this.entityData.sum = this.entity.sum_to_pay
      this.displayPaymentDialog = true
    },

    // Technical
    async initPage() {
      if ( !this.code ) {
        return
      }
      let result = await this.GET_REFERRAL_DETAILS(this.code)
      if ( !result ) {
        return
      }
      result.payments.reverse()
      result.orders.reverse()
      result.users.reverse()
      this.entity = result.referral
      this.users = result.users
      this.orders = result.orders
      this.payments = result.payments
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>